.footer {
    position: relative;
    background: $blue;
    padding: 30px 0 0;

    border-top: 5px solid $orange;
    text-align: center;

    @include media('>=tablet') {
        border-width: 8px 0 0;
    }

    @include media('>=desktop') {
        padding: 55px 0 0;
    }
    
    .nav-wrapper {
        color: $white;
        font: 400 18px/24px $font-face-primary;

        @include media('>=tablet') {
            font-size: 24px;
        }

        @include media('>=desktop') {
            font-size: 26px;
        }

        p {
            @include media('>=tablet') {
                margin: 0 0 25px;
            }

            @include media('>=desktop') {
                margin: 0 0 35px;
            }
        }
    }
    .nav {
        > li {
            float:left;
            list-style:none;
            margin-left:0px;
            a {
                display:block;
                font-size:14px;
                margin:0px;
                color:grey;
                text-decoration: none;
                background:transparent;
                padding:6px 10px;
                &:hover {
                    color:blue;
                }
            }
            ul {
                padding: 10px 15px;
                position:absolute;
                background:grey;
                width:250px;
                top:100%;
                display:none;
                li {
                    list-style:none;
                    a {
                        
                    }
                }
                
            }
            &:hover {
                .dropdown {
                    display:block;
                }
            }
        }
    }
    
    .nav {
        position:relative;
        padding:0px; 
        margin:0px;
    }
    
    .social-links {
        @extend %listreset;
        letter-spacing: -5px;

        @include media('>=desktop') {
            margin: 0 0 44px;
        }

        li {
            display:inline-block;
            vertical-align:top;
            margin: 0 5px 10px;

            a{
                transition: opacity 0.25s linear 0s;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
        
    }
    
    address {
        color: $white;
        font-size: 16px;
        line-height: 20px;

        @include media('>=desktop') {
            margin: 0 0 55px;
        }

        a{
            color: $white;

            &:hover {
                color: $orange;
            }
        }
    }


    .logo {
        margin: 0 0 15px;
        width: 120px;
        display:inline-block;
        vertical-align:top;

        @include media('>=tablet') {
            width: 150px;
            margin: 0 0 25px;
        }

        @include media('>=desktop') {
            width: 171px;
            margin: 0 0 36px;
        }
    }
}



.digistorm {
    position:relative;
    background: $blue;
    padding:10px 0 25px;
    color: rgba($white,0.6);
    font-size: 13px;
    line-height: 17px;
    text-align: center;

    span {
        display: block;
    }

    a{
        color: rgba($white,0.6);

        &:hover {
            color: $white;
        }
    }
    
    .logo {
        width: inherit;
        margin: 0;

        img {
            height:20px;   
        }
    }

    .nav {
        li {
            margin: 0 0 8px;
        }
    }   
}

