/* Generated by Font Squirrel (https://www.fontsquirrel.com) on June 28, 2016 */
@font-face {
    font-family: 'gotham book';
    src: url('../fonts/gotham-book-webfont.eot');
    src: url('../fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-book-webfont.woff2') format('woff2'),
         url('../fonts/gotham-book-webfont.woff') format('woff'),
         url('../fonts/gotham-book-webfont.ttf') format('truetype'),
         url('../fonts/gotham-book-webfont.svg#gotham_bookregular') format('svg');
    font-weight: normal;
    font-style: normal;

}