.header-block {
    font-family: $font-face-primary;
    margin: 0 0 25px;
    
    @include media('>=tablet') {
        position: relative;
        z-index: 9;
        border-top: 5px solid $orange;
    }

    @include media('>=desktop') {
        border-top: 8px solid $orange;
    }
    
    .banners {
        height: inherit;
        min-height: inherit;
        position:relative;
        margin: 0;

        @include media('>=tablet') {
            height: 100%;
        }

        &:hover {
            .caption-block {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
        .banner-wrapper {
            height: inherit;
            outline:none !important;
            min-height: inherit;

            @include media('>=tablet') {
                height: 100%;
            }

            .banner {
                height: inherit;
                min-height: inherit;
                background-size:cover;
                background-position:center center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                @include media('>=tablet') {
                    height: 100%;
                }
            }

            >.container-fluid {
                display: table;
                table-layout: fixed;
                width: 100%;
                

                @include media('>=tablet') {
                    height: 500px;
                }

                @include media('>=desktop') {
                    height: 90vh;
                }
            }
        }

        .caption-block {
            color: $white;
            //visibility: hidden;
            //opacity: 0;
            transition: opacity 0.25s linear;
            font-size: 12px;
            line-height: 17px;
            padding: 0 0 20px;
            position: relative;
            z-index: 10;

            @include media('>=tablet') {
                display: table-footer-group;
                height: 1%;
            }

            .holder {
                max-width: 1400px;
                margin: 0 auto;
            }

            .info-list {
                @extend %listreset;
                font-size: 10px;
                line-height: 12px;

                @include media('>=tablet') {
                    font-size: 12px;
                    line-height: 17px;
                    padding: 0 0 30px;
                }

                @include media('>=desktop') {
                    font-size: 14px;
                    line-height: 18px;
                    padding: 0 0 57px;
                }

                @include media('>=1600px') {
                    font-size: 16px;
                    line-height: 20px;
                }

                li {
                    padding: 0 0 5px 10px;
                    position: relative;

                    @include media('>=tablet') {
                        padding: 0 0 8px 10px;
                    }

                    @include media('>=desktop') {
                        padding: 0 0 8px 16px;
                    }

                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "\e914";
                        font-family: 'icomoon';
                        font-size: 7px;

                        @include media('>=tablet') {
                            top: -1px;
                        }

                        @include media('>=desktop') {
                            top: 0;
                            left: 0;
                            font-size: 9px;
                        }
                    }
                }
            }

            .caption-area {
                float: left;
                
                @include media('<tablet') {
                    width: 35%;
                }

                @include media('<phone') {
                    width: 50%;
                }

                .info-holder {
                    display:inline-block;
                    vertical-align:top;
                    text-align: left;
                }

                &.pull-right {
                    text-align: right;
                }
            }

            .title {
                text-transform: uppercase;
                display: block;
                padding: 0 0 5px;
                font-weight: normal;

                @include media('>=tablet') {
                    font-size: 16px;
                    line-height: 18px;
                    margin: 0 0 10px;
                }

                @include media('>=desktop') {
                    font-size: 18px;
                    line-height: 22px;
                    margin: 0 0 21px;
                }
            }
        }

        .banner-overlay {
            position:absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: inherit;
            background: rgba($light-blue,0.7);
        }

        &.content-banners {
            height:500px;

            .banner {
                height:500px;
            }

            .banner-overlay {
                display:none;
            }
        }
        
        // slick js styles
        .slick-arrow {
            position:absolute;
            top:50%;
            background:none;
            border:none;
            color:blue;
            font-size:48px;
            outline:none;
            z-index:100;
            transform:translateY(-50%);

            &.slick-next {
                right:10px;
            }

            &.slick-prev {
                left:10px;
            }
        }
    }
}

.banner-area {
    // height: 250px;
    // background-size: cover;
    // background-position: 50% 50%;
    position: relative;
    // z-index: 1;

    @include media('>=desktop') {
        // height: 560px;
        max-height: 560px;
        overflow: hidden;
    }
    img.image-banner-responsive {
        width: 100%;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 2;
        background: rgba($black,0.3);

        @include media('>=tablet') {
            display: none;
        }
    }
}

.navbar {
    position:absolute;
    height:40px;
    background: $blue;
    width:100%;
    z-index:100;
    top:0px !important;
    border-radius:0px !important;

    @include media('>=tablet') {
        position: static;
        margin: 0;
        border-radius: 0;
        text-align: center;
    }

    @include media('>=desktop') {
        height: 80px;
    }

    @include media('>=1600px') {
        height: 100px;
    }

    .home & {
        @include media('>=desktop') {
            height: 80px;
        }
    }

    .container-fluid {
        position:relative;
        height:100%;
    }

    .nav-wrapper {
        position:relative;
        height:100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align:center;        
        align-items:center;
        z-index: 2;

        @include media('>=tablet') {
            padding-right: 30px;
        }

        @include media('>=1600px') {
            padding-right: 0;
        }
        
    }
    
    .nav {
        padding: 0; 
        margin: 0;
        background: $light-blue;
        position: fixed;
        width: 320px;
        top: 58px;
        right: -320px;
        bottom: 0;
        transform: translateX(0);
        transition: transform 0.4s;
        overflow: auto;

        .home & {
            @include media('<tablet') {
                left: -200px;
                right: auto;
            }
        }

        @include media('>=tablet') {
            position: static;
            display:inline-block;
            vertical-align:top;
            background: none;
            width: inherit;
            padding: 14px 0 0;
            overflow: visible;
            max-width: 78%;
        }

        @include media('>=desktop') {
            padding: 17px 0 0;
        }

        .nav-active & {
            transform:translateX(-320px);
        }

        .nav-active .home & {
            @include media('<tablet') {
               transform:translateX(320px);
            }
        }

        > li {
            position:relative;
            display:block;
            text-transform: uppercase;
            border-bottom: 1px solid darken($blue,5%);

            @include media('>=tablet') {
                float:left;
                list-style:none;
                margin-left:0px;
                position: relative;
                padding: 0 0 15px;
                margin: 0 5px;
                border: 0;
            }

            @include media('>=desktop') {
                padding: 0 0 20px;
                margin: 0 12px;
            }

            @include media('>=1600px') {
                padding: 0 0 22px;
                margin: 0 22px;
            }
            @media screen and (min-width:1024px) and (max-width:1139px){
                margin: 0 10px;
            }

            a {
                display:block;
                font-size:16px;
                margin:0px;
                color: $white;
                text-decoration: none;
                background:transparent;
                padding:6px 10px;

                @include media('>=tablet') {
                    padding: 0;
                    font-size: 12px;
                    line-height: 17px;
                }

                @include media('>=desktop') {
                    font-size: 16px;
                    line-height: 20px;
                }
                @media screen and (min-width:1024px) and (max-width:1139px){
                    font-size: 14px !important;
                }
            }
            &.active,
            &:hover {
                > a {
                    background: lighten($blue,5%);

                    @include media('>=tablet') {
                        background: none;
                        color: #92aaeb;
                    }
                }
            }

            &:last-child {
                ul {
                    @include media('>=tablet') {
                        left: auto;
                        right: 0;

                        &:before {
                            left: auto;
                            right: 13px;
                        }
                    }
                }
            }

            > ul {
                @extend %listreset;
                background: #303c65;
                width:250px;
                @media screen and (min-width:768px){
                    display:none;
                }

                @include media('>=tablet') {
                    text-align: left;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 999;
                    padding: 15px 0 15px;
                }

                @include media('>=desktop') {
                    width: 300px;
                }

                @include media('>=1600px') {
                    width: 370px;
                }

                &:before {
                    @include media('>=tablet') {
                        content: '';
                        top: 0;
                        left: 13px;
                        @include triangle(24px, #303c65, up);
                        position: absolute;
                        top: -12px;
                    }
                }

                li {
                    list-style:none;
                    a {
                        color:white;
                        padding:8px 25px;
                        font-size: 12px;
                        line-height: 14px;

                        @include media('>=desktop') {
                            font-size: 15px;
                            line-height: 18px;
                            padding: 10px 15px;
                        }

                        &:hover {
                            color: $white;
                            background: darken($sky-blue,15%);
                        }
                    }
                }
            }
            &:hover {
                .dropdown {
                    @media screen and (min-width:768px){
                        display:block;
                    }
                }
            }
        }
    }

    .nav-toggle {
        position: absolute;
        width: 30px;
        height: 18px;
        top: 50%;
        right: 0;
        margin-top: -10px;
        transform: rotate(0deg);
        transition:transform 0.4s;
        cursor: pointer;

        .home & {
            left: 0;
        }

        &:hover {
            opacity: 0.8;
        }

        .line {
            background-color: $white;
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            transform: translate(0px, 0px) rotate(0deg);
            transition: transform 0.4s, opacity 0.4s;
            
            &:nth-child(1) {
                top: 0%;
            }
            &:nth-child(2) {
                top: 50%;
                opacity: 1;
            }
            &:nth-child(3) {
                top: 100%;
            }
        }

        &.active {
            transform: rotate(180deg);
            .line {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(5px, 6.7px);
                }
                &:nth-child(2) {
                    opacity:0
                }
                &:nth-child(3) {
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
    }   
}

.logo {
    margin-top: 37px;
    display: block;
    position: relative;
    z-index: 99;
    transition: opacity 0.25s linear;

    &:hover {
        opacity: 0.8;
    }
}

.form-block {
    &.form-active{
        .input-group {
            visibility: visible;
            opacity: 1;
        }

        .close {
            visibility: visible;
            opacity: 1;
        }

        .show {
            visibility: hidden;
            opacity: 0;
        }
    }

    .input-group {
        visibility: hidden;
        position: absolute;
        top: 49px;
        right: 0;
        width: 250px;
        opacity: 0;
        background: #445696;
        padding: 5px;
        transition: opacity 0.25s linear 0s;
        z-index: 2;

        @include media('>=tablet') {
            top: 49px;
        }

        @include media('>=desktop') {
            top: 79px;
        }

        @include media('>=1600px') {
            top: 99px;
        }

        .home & {
            @include media('>=desktop') {
                top: 79px;
            }
        }
    }

    .close {
        visibility: hidden;
        opacity: 0;
    }
}

.search-form {
    .form-control {
        height: 39px;
    }
}

.form-opener {
    color: $white;
    position: absolute;
    right: 50px;
    top: 24px;
    font-size: 20px;
    cursor: pointer;

    .home & {
        @include media('<tablet') {
         right: 15px;   
        }

        @include media('>=1600px') {
            top: 38px;
        }
    }

    @include media('>=tablet') {
        right: 11px;
    }

    @include media('>=desktop') {
        right: 11px;
        top: 39px;
        font-size: 21px;
    }

    @include media('>=1600px') {
        top: 50px;
    }

    span {
        position: absolute;
        transition: opacity 0.25s linear 0s;
        top: 50%;
        transform: translate(-50%,-50%);
        left: 50%;

        &.close {
            font-size: 25px;
        }
    }

    &:hover{
        color: #92aaeb;
    }
}

.logo-holder {
    width: 170px;
    padding: 10px 46px 30px;
    margin: 0 auto;
    position: relative;
    z-index: 6;
    height: 181px;
    text-align: center;

    @include media('>=tablet') {
        width: 280px;
        padding-top: 30px;
        height: 461px;
        margin: -8px auto 3px;
    }

    @include media('>=desktop') {
        z-index: 99;
        margin-top: -8px;
    }

    @include media('>=1600px') {
        width: 377px;
        padding: 64px 5px;
        height: 700px;
    }

    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -25px;
        content: '';
        width: 0;
        height: 0;
        border-top: 180px solid #ecebf2;
        border-left: 110px solid transparent;

        @include media('>=tablet') {
            border-top: 450px solid #ecebf2;
            border-left: 320px solid transparent;
            left: -180px;
        }

        @include media('>=desktop') {
            border-top: 650px solid #ecebf2;
            border-left:  400px solid transparent;
            left: -260px;
        }

        @include media('>=1600px') {
            border-top: 898px solid #ecebf2;
            border-left: 550px solid transparent;
            left: -361px;
        }
        // @media screen and (min-width: 768px) and (max-width: 1024px) {
        //     border-top: 450px solid #ecebf2 !important;
        // }
    }

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -35px;
        content: '';
        width: 0;
        height: 0;
        left: auto;
        border-top: 180px solid #dddde8;
        border-right: 110px solid transparent;
        right: -25px;

        @include media('>=tablet') {
            right: -180px;
            left: auto;
            border-top: 450px solid #dddde8;
            border-right: 320px solid transparent;
        }
        // @media screen and (min-width: 768px) and (max-width: 1024px) {
        //     border-top: 450px solid #dddde8 !important;
        // }

        @include media('>=desktop') {
            border-top: 650px solid #dddde8;
            border-right: 400px solid transparent;
            left: auto;
            right: -260px;
        }

        @include media('>=1600px') {
            right: -361px;
            left: auto;
            border-top: 898px solid #dddde8;
            border-right: 550px solid transparent;
        }
    }

    .logo {
        margin: 0;
        display: block;

        @include media('>=tablet') {
            padding: 25px;
        }

        @include media('>=desktop') {
            padding: 5px;
        }

        @include media('>=1600px') {
            padding: 25px;
        }
    }
}

.navbar {
    @include media('>=tablet') {
        background: $blue;
    }

    .logo {
        display: none;

        @include media('>=tablet') {
            display: block;
            float: left;
            width: 30px;
            margin: 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        @include media('>=desktop') {
            width: 39px;
        }
        @media screen and (max-width:767px){
            display:block !important;
            width:30px !important;
            margin-top:0px !important;
        }
    }

    .logo-block {
        width: 150px;
        padding: 10px 48px 30px;
        margin: 89px 0 0;
        position: relative;
        z-index: 2;
        height: 151px;
        float: left;
        text-align: center;
        transition: width 0.3s, padding 0.3s,margin 0.3s,height 0.3s;

        @include media('>=tablet') {
             padding: 15px 50px 30px;
        }

        @include media('>=desktop') {
            width: 180px;
            padding: 7px 55px 30px;
            margin: 55px 0 0;
        }

        @include media('>=1600px') {
            width: 287px;
            padding: 26px 98px 30px;
            margin: 34px 0 0;
        }

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -5px;
            content: '';
            width: 0;
            height: 0;
            border-top: 120px solid #ecebf2;
            border-left: 80px solid transparent;

            @include media('>=desktop') {
                border-top: 172px solid #ecebf2;
                border-left: 94px solid transparent;
                left: -4px;
            }

            @include media('>=1600px') {
                border-top: 250px solid #ecebf2;
                border-left: 148px solid transparent;
            }
        }

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -5px;
            content: '';
            width: 0;
            height: 0;
            left: auto;
            border-top: 120px solid #dddde8;
            border-right: 80px solid transparent;

            @include media('>=desktop') {
                border-top: 172px solid #dddde8;
                border-right: 94px solid transparent;
                left: auto;
                right: -4px;
            }

            @include media('>=1600px') {
                border-top: 250px solid #dddde8;
                border-right: 148px solid transparent;
            } 
        }

        .logo {
            display: block;
            width: 100%;
            margin: 0;

            img {
                width: 100%;
            }
        }
    }
}
#header.home-header{
    border-top: 0px solid #DC241F;
    transition: border 0.3s;
    &.position-fixed{
        position: fixed;
        width: 100%;
        top: 0px;
        border-top: 8px solid #DC241F;
        transition: border 0.3s;
    }
    @media screen and (max-width: 767px){
        border-top: 8px solid #DC241F;
        transition: border 0.3s;
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 100;
        .navbar{
            top: 0px;
        }
    }

}
.inside-page-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
    border-top: 8px solid #DC241F;
}
.inside-page-header{
    &.position-fixed{
        .logo-block {
            width: 39px;
            padding: 0px !important;
            margin: 0px !important;
            transition: width 0.3s, padding 0.3s,margin 0.3s,height 0.3s;
            height: auto;
            .logo {
                line-height: 0px;
            }
        }
        .after-scroll-logo{
            opacity:1;
            z-index: 1;
            width: 55% !important;
            transition: opacity 0.3s, z-index 0.3s, width 0.3s, padding 0.3s;
            @media screen and (min-width: 767px){
                padding: 10px 0px;
                width: 100% !important;
                // padding: 10px 0px;
                // width: 62% !important;
            }
        }
        .before-scroll-logo{
            opacity:0;
            z-index: -1;
            width: 0% !important;
            transition: opacity 0.3s, z-index 0.3s, width 0.3s;
        }
    }
    .after-scroll-logo{
        opacity:0;
        z-index: -1;
        width: 0% !important;
        padding:0px;
        transition: opacity 0.3s, z-index 0.3s, width 0.3s, padding 0.3s;
    }
    .before-scroll-logo{
        opacity:1;
        z-index: 1;
        width:100% !important;
        transition: opacity 0.3s, z-index 0.3s, width 0.3s;

    }
}
.position-fixed .navbar .logo-block .logo img.after-scroll-logo {
    width: 50%;
}
.inside-page-header {
    @media screen and (max-width: 767px){
        .navbar{
            top:0px;
        }
    }
    .navbar .logo-block:before, .navbar .logo-block:after {
        transition: opacity 0.3s, z-index 0.3s, top 0.3s;
        opacity: 1 !important;
        z-index: 1;
        top:0px;
        transition: opacity 0.9s, z-index 0.3s, top 0.3s;
    }
    &.position-fixed{
        .navbar .logo-block:before, .navbar .logo-block:after {
            transition: opacity 0.1s, z-index 0.3s, top 0.3s;
            opacity: 0 !important;
            z-index: -1;
            top:-150px
        }
    }
}