html {
  background: $blue;
}

body {
  min-width: 320px !important;

  @include media('>=desktop') {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  // &.nav-active {
  //   overflow: hidden;
  // }
}

img {
  max-width: 100% !important;
  height: auto !important;
  vertical-align: top !important;
}

a {
    transition: color 0.25s linear !important;

    &:focus {
      outline: none !important;
      outline-offset: 0 !important;
    }
}

p {
    margin: 0 0 15px !important;

    @include media('>=desktop') {
        margin: 0 0 26px !important;
    }
}

h1,
.h1 {
    margin: 0 0 15px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
    color: #2a2a2a !important;

    @include media('>=desktop') {
        font-size: 36px !important;
        line-height: 40px !important;
        margin:  0 0 28px !important;
    }
}

h2,
.h2 {
    color: $orange !important;
    margin: 0 0 10px !important;
    font-weight: 400 !important;

    @include media('>=desktop') {
        margin: 0 0 5px !important;
    }
}

h3,
.h3 {
    margin: 0 0 10px !important;
    font-weight: 400 !important;
    color: #1d345d !important;
}

h4,
.h4 {
    margin: 0 0 8px !important;
    font-weight: 400 !important;
    color: #1d345d !important;
    font-family: $font-family-sans-serif !important;
}

h5,
.h5,
h6,
.h6 {
    margin: 0 0 5px !important;
    font-weight: 400 !important;
    color: #1d345d !important;
    font-family: $font-family-sans-serif !important;
}

.container-fluid {
    max-width: 1400px;
    margin: 0 auto;
}

#wrapper {
  overflow:hidden;
  width:100%;
  position:relative;
  padding-top: 50px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: $orange;
    z-index: 9;
    content: '';

    @include media('>=tablet') {
        display: none;
    }
  }

  @include media('>=tablet') {
    padding: 0;
  }
}

.two-cols {
    .banner-overlay {
        @include media('>=tablet') {
            max-width: 80%;
        }

        @include media('>=desktop') {
            max-width: 65.5%;
        }
    }
}

.banners {
    min-height: 350px;
    position:relative;
    margin: 0 0 25px;

    @include  media('>=tablet') {
        height: 490px;
    }

    @include media('>=desktop') {
        height: 606px;
    }

    .banner-wrapper {
        min-height: 350px;
        outline:none !important;

        @include  media('>=tablet') {
            height: 490px;
        }

        @include media('>=desktop') {
            height: 606px;
        }

        .banner {
            min-height: 350px;
            background-size:cover;
            background-position:center center;

            @include  media('>=tablet') {
                height: 490px;
            }

            @include media('>=desktop') {
                height: 606px;
            }
        }
    }
    .container {
        position:relative;
    }
    .banner-overlay {
        padding:10px 20px 30px;
        background: $blue;
        color: $white;
        font-size: 15px;
        line-height: 20px;
        display: block;
        text-align: center;
        font-family: $font-face-primary;

        @include media('>=tablet') {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        @include media('>=desktop') {
            font-size: 32px;
            line-height: 52px;
            padding: 19px 15px 46px;
        }
    }
    &.content-banners {
        height:500px;
        .banner {
            height:500px;
        }
        .banner-overlay {
            display:none;
        }
    }
    
    // slick js styles
    .slick-arrow {
        position:absolute;
        top:50%;
        background:none;
        border:none;
        color:blue;
        font-size:48px;
        outline:none;
        z-index:100;
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%);
        &.slick-next {
            right:10px;
        }
        &.slick-prev {
            left:10px;
        }
    }

    .slick-dots,
    .pagination {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 0px;
        text-align: center;
        bottom: 8px;

        @include media('>=tablet') {
            max-width: 80%;
        }

        @include media('>=desktop') {
            bottom: 19px;
            max-width: 65.5%;
        }

        li {
            list-style: none;
            display: inline-block;
            margin: 0 3px;
            width: 12px;
            height: 12px;
            font-size:0;
            line-height:0;
            background-color: #253967;
            border-radius: 100%;
            cursor: pointer;

            &:hover {
                background-color: $white;
            }

            button {
                background: none;
                border: none;
                outline: none;
                background-color: white;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                color: transparent;
                background-color: #253967;
            }
            &.slick-active,
            &.active {
                background-color: $white;

                button {
                    background-color: #253967;
                    cursor: pointer;
                }
            }
        }
    }
}

.sidebar {
    margin: 0 0 17px;

    .two-columns & {
        font-family: $font-face-primary;
        text-transform: uppercase;
    }

    .title-text {
        color: $white;
        padding:10px 20px;
        background: $blue;
        display: block;
        font-size: 16px;
        line-height: 20px;

        @include media('>=desktop') {
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            padding: 14px 17px 13px;
        }
    }

    .nav {
        padding:0;
        ul{
            padding:0px;
            li.subpage{
                a{
                    padding-left:20px !important;
                }
            }
        }

        li {
            list-style:none;
            margin:0;
            // border-bottom: 2px solid #f2f2f2;

            .two-columns & {
                @include media('>=desktop') {
                    margin: 0 0 23px;
                    // padding: 0 0 21px;
                }
            }

            a {
                color: $blue;
                display:block;
                padding: 10px 20px;
                font-size: 15px;
                line-height: 20px;

                .two-columns & {
                    padding: 10px 0;
                    font-size: 13px;
                    line-height: 18px;
                }

                @include media('>=desktop') {
                    color: $blue;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 23px;
                    padding: 12px 8px 13px;

                    .two-columns & {
                        font-size: 16px;
                        line-height: 18px;
                        padding: 0;
                    }
                }

                &:hover {
                    color: #c63d2d;
                    background: none;
                }
            }
            &.active{
                a{
                    color: #c63d2d;
                    background: none;
                }
            }
        }
        > li {
            ul {
                padding:0;

                li {
                    a {
                        font-size:13px;
                        padding:2px 45px;
                    }
                }
            }
        }
    }
}

.gallery-block {
    display: block;

    &:hover{
        .gallery-list{
            li{
                &.video-frame{
                    &:after{
                        //background: rgba(37, 56, 103, 0.7);

                    }
                    opacity: 0.8;
                }
                span{
                    img{
                        opacity: 0.8;
                    }
                }
            }
        }
        .link-media{
                background: $light-blue;
        }
    }

    .gallery-list {

       


        @extend %listreset;
        column-count: 2;
        column-gap: 0;
        letter-spacing: -5px;

        li {
           display:inline-block;
           vertical-align:top;
            width: 50%;
            overflow: hidden;

            &.large-block {
               width: 100%;

               &.video-frame {
                span {
                    &:before {
                        border-top: 25px solid transparent;
                        border-bottom: 25px solid transparent;
                        border-left: 44px solid #fff;
                    }
                }
               }
            }

            &.video-frame {
                transition: opacity 0.3s;
                div{
                    position: relative;
                    z-index: 1;
                    display: block;

                    &:hover {
                        &:after {
                           //background: rgba(37, 56, 103, 0.7);
                        }
                    }

                    &:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        content: '';
                        z-index: 2;
                        background: rgba(37, 56, 103, 0.5);
                        transition: background 0.25s linear 0s;
                    }

                    &:before {
                        border-top: 12px solid transparent;
                        border-bottom: 12px solid transparent;
                        border-left: 22px solid #fff;
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 3;
                        transform: translate(-50%,-50%);
                    }
                }
            }

            div{
                &:hover {
                    img {
                        //opacity: 0.8;
                    }
                }
            }

            img {
                width: 100%;
                transition: opacity 0.25s linear 0s;
            }
        }
    }
    
    .link-media {
        color: $white;
        display: block;
        background: $blue;
        padding: 15px;
        transition: background 0.25s linear 0s;
        text-align: center;

        @include media('>=desktop') {
            font-size: 16px;
            line-height: 20px;
            padding: 27px 15px;
        }

        
    }
}

.quicklinks {
    padding:30px 0;
    margin:0 auto;

    [class^="col-"], [class*=" col-"] {
        padding-bottom: 25px;

        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            float: none;
        }

        @include media('>=desktop') {
            padding-bottom: 22px;
        }
    }
 
    .row {
        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 25px;
            margin-left: 0;
            margin-right: 0;
        }

        @include media('>=desktop') {
            margin-bottom: 37px;
        }

        &:before,
        &:after {
            @include media('>=tablet') {
                display: none;
            }
        }
    }

    .quicklink {
        text-align:center;
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        .ico-holder {
            color: $blue;
            width: 130px;
            height: 112px;
            margin: 0 auto 25px;
            font-size: 30px;
            position: relative;
            z-index: 1;
            background: #f2f2f2 url('/app/images/bg-traingle.jpg') 50% 50%;
            background-size:cover;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            padding-top: 9px;
            text-align: center;

            @include media('>=desktop') {
                width: 240px;
                height: 205px;
                padding-top: 47px;
                margin: 0 auto 33px;
            }

            @include media('>=widescreen') {
                width: 260px;
                height: 225px;
                padding-top: 44px;
            }

            img {
                width: auto;
                margin: 0 auto;

                @include media('<desktop') {
                    max-width: 40px;
                }
            }
        }

        &:hover {
            .link-more,
            .h1 {
                color: $orange;
            }
        }

        @include media('>=tablet') {
            font-size: 14px;
            line-height: 18px;
        }

        @include media('>=desktop') {
            font-size: 16px;
            line-height: 20px;
        }

        img {
            display:block;
            max-width:100%;
            width:100%;
        }

        .link-more {
            display:block;
            //color: #4b6fc1;
            color: $blue;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;

            @include media('>=tablet') {
                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;
                text-align: center;
            }
        }
    }

    .h1 {
        font-size: 20px;
        line-height: 25px;
        color: #1f1f1f;
        margin: 0 0 15px;
        font-weight: 400;

        @include media('>=tablet') {
            font-size: 18px;
            line-height: 22px;
        }

        @include media('>=desktop') {
            font-size: 25px;
            line-height: 30px;
            margin: 0 0 30px;
        }

        @include media('>=widescreen') {
            font-size: 32px;
            line-height: 36px;
        }
    }
}

.heading-block {
    text-align: center;
    overflow: hidden;

    .h1 {
        display:inline-block;
        vertical-align:top;
        max-width: 70%;
        margin: 0 auto 20px;
        position: relative;
        padding: 0 10px;
        font-size: 16px;
        line-height: 20px;
        color: #2a2a2a;

        @include media('>=desktop') {
            font-size: 32px;
            font-weight: 400;
            line-height: 30px;
            padding: 0 60px;
            margin: 0 auto 56px;
        }

        &:before,
        &:after {
            content: '';
            height: 2px;
            left: -9999px;
            right: 100%;
            background: $orange;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }

        &:after {
            left: 100%;
            right: -9999px;
        }
    }
}

.content-block {
    padding-top: 15px;

    @include media('>=desktop') {
        padding: 47px 0 21px;
    }
}

.events {
    padding:50px 0;
    margin:0 auto;
    text-transform: uppercase;

    @include media('>=desktop') {
        padding: 50px 0 56px;
    }

    [class^="col-"], [class*=" col-"] {
        padding-bottom: 15px;

        @include media('>=tablet') {
            float: none;
            display: flex;
        }
    }

    .row {
        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
        }

        &:before {
            display: none;
        }
    }

    .event {
        display: block;
        padding: 15px;
        background: #f2f2f2 url(/app/images/bg-event-block.png) 50% 50%;
        background-size:cover;
        border-radius: 4px;
        transition: background 0.25s linear 0s;
        width:100%;

        @include media('>=desktop') {
            padding: 24px 31px 28px;
        }

        &:hover {
            background: $blue;

            *,
            .event-details .description {
                color: $white;
            }
        }

        .event-date {
            display: block;
            // color: #6a84c9;
            color: $blue;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            margin: 0 0 12px;
        }
        
        .event-details {
            .description {
                color: #1f1f1f;
                font-size: 15px;
                line-height: 18px;
                display: block;
                margin: 0 0 20px;

                @include media('>=desktop') {
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }

        .time {
            display: block;
            color: #b6b6b6;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
        }
    }
}

.stat-block {
    margin: 0 0 25px;
    padding: 0 15px;

    @include media('>=desktop') {
        margin: 0 0 76px;
    }

    .stat-list {
        @extend %listreset;
        border-radius: 4px;
        overflow: hidden;
        background: rgba($blue,0.9) url('/app/images/bg-stat-block.png') 50% 50%;
        background-size:cover;
        letter-spacing: -5px;
        margin: -1px -12px;
        display: flex;
        flex-wrap: wrap;

        @include media('>=tablet') {
            margin: 0;
            padding: 15px;
        }

        @include media('>=desktop') {
            padding: 25px 0 24px;
        }

        li {
            width: 50%;
            letter-spacing: 0;
            text-align: center;
            color: #f2f2f2;
            padding: 10px;
            border: 1px solid rgba($white,0.2);

            +li {
                @include media('>=tablet') {
                    border-left: 2px solid rgba($white,0.2);
                }
            }

            @include media('>=tablet') {
                width: 25%;
                border: 0;
                padding: 15px;
            }

            @include media('>=desktop') {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                padding: 10px 37px 11px;
            }

            .num {
                color: $white;
                font-size: 30px;
                line-height: 35px;
                display:inline-block;
                vertical-align:top;
                margin: 0 0 15px;
                font-family: $font-face-primary;

                @include media('>=desktop') {
                    font-size: 64px;
                    line-height: 61px;
                    margin: 0 0 12px;
                }
            }

            p {
                @include media('>=desktop') {
                    margin: 0 0 5px;
                }
            }
        }
    }
}

.news {
    font-family: $font-family-sans-serif;

    .label {
        display:inline-block;
        vertical-align:top;
        margin: 15px 0 10px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        //color: #4b6fc1;
        color: $blue;

        @include media('>=desktop') {
            margin: 29px 0 18px;
        }
    }

    h3 {
        margin: 0 0 15px;
        color: #3d3d3d;
        font: 400 16px/20px $font-family-sans-serif;

        @include media('>=desktop') {
            font-size: 18px;
            line-height: 23px;
        }
    }

    .article {
        color: #4a4a4a;
        font-size: 14px;
        line-height: 18px;

        @include media('>=desktop') {
            font-size: 16px;
            line-height: 20px;
        }

        .img-responsive {
            transition: opacity 0.25s linear 0s;
            margin: 0 auto;
        }

        &:hover {
            .img-responsive {
                opacity: 0.8;
            }

            h3 {
                color: $orange;
            }
        }
    }

    .date {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        display: block;
        color: #b6b6b6;

        @include media('>=tablet') {
            position: absolute;
            bottom: 0;
            left: 15px;
        }
    }

    [class^="col-"], [class*=" col-"] {
        padding-bottom: 25px;
        overflow: hidden;

        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            float: none;
        }

        @include media('>=desktop') {
            padding-bottom: 16px;
        }
    }
 
    .row {
        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 25px;
        }

        @include media('>=desktop') {
            margin-bottom: 24px;
        }

        &:before,
        &:after {
            @include media('>=tablet') {
                display: none;
            }
        }
    }
}

.videos-block {
    @extend .news;
    padding: 0 0 25px;

    @include media('>=desktop') {
        padding: 0 0 64px;
    }

    .heading-block {
        @include media('>=desktop') {
            margin: 0 0 -5px;
        }
    }

    .label {
        a {
            // color: #4b6fc1;
            color: $blue;
        }

        @include media('>=desktop') {
            margin: 19px 0 17px;
        }
    }

    h3 {
        a{
            color: #3d3d3d;
        }

        @include media('>=desktop') {
            margin: 0 0 30px;
        }
    }
}

.video-holder {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    min-height: 150px;
    background-size: cover;
    background-position: 50% 50%;

    @include media('>=desktop') {
        min-height: 231px;
    }

    .embed-responsive-item {
        height: auto;
    }


    &:before {
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 42px solid $white;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translate(-50%,-50%);
    }

    &:hover {
        &:after {
            background: rgba(0, 34, 76, 0.7);
        }
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: rgba(0, 34, 76, 0.5);
        z-index: 1;
        transition: background 0.25s linear 0s;
    }

    .duration {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 4px;
        text-align: center;
        width: 64px;
        height: 32px;
        font-size: 18px;
        line-height: 23px;
        color: $white;
        background: $blue;
        z-index: 2;
    }
}

.quote {
    font-family: $font-face-primary;
    padding: 25px 0 25px;
    //color: #3a548b;
    color: $blue;
    text-align: center;
    position: relative;

    @include media('>=desktop') {
        padding: 41px 0 25px;
    }

    &:before {
        display: none;
        width: 160px;
        height: 3px;
        content: '';
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: $orange;
        position: absolute;
    }

    .title {
        font-size:18px;
        font-weight:400;
        line-height: 23px;
        display: block;
        margin: 0 0 10px;

        @include media('>=desktop') {
            font-size: 24px;
            line-height: 34px;
            margin: 0 0 36px;
        }
    }

    .byline {
        color: #84878a;
        font-size: 16px;
        line-height: 15px;

        &:before {
            display:inline-block;
            vertical-align:top;
            content: '';
            width: 11px;
            height: 1px;
            background: #84878a;
            margin: 8px 5px 0 0;

            @include media('>=desktop') {
                margin: 14px 5px 0 0;
            }
        }
    }
}

.intro-text {
    color: #1d345d;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: 0 0 15px;
    font-weight: 400;

    @include media('>=desktop') {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 31px;
    }
}

.form-control {
    border: 0;
    box-shadow: none;
    font-size: 14px;
    line-height: 18px;
    height: 52px;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.info-form {
    padding: 0 0 25px;

    @include media('>=desktop') {
        padding: 5px 0 48px;
    }

    label {
        font-weight: 400;
        display:inline-block;
        vertical-align:top;
        margin: 0 0 10px;
        font-size: 16px;
        line-height: 18px;
    }

    .col {
        padding: 0 0 10px;

        @include media('>=tablet') {
            float: left;
            width: 50%;
            padding: 0 10px;
        }
    }

    .holder {
        @extend %clearfix;

        @include media('>=tablet') {
            margin: 0 -10px 20px;
        }
    }

    .fake-select{
        position:relative;
        display:inline-block;
        width: 100%;
    }
    select::-ms-expand {display: none;}
    select{
        display:inline-block;
        margin:0;
        outline:none;
        -webkit-appearance:none;
        appearance:none;
        width:100%;
        position: relative;
        color: $input-color-placeholder;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 45px;
    }

    .fake-select:after{
        @include triangle(11px, #838689, down);
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
    }

    input[type='submit'],
    input[type='reset'] {
        min-width: 132px;
        margin: 0 5px 10px;
        font-family: $font-face-primary;

        @include media('>=tablet') {
            margin: 0 10px 10px 0;
        }

        @include media('>=desktop') {
            margin: 0 17px 10px 0;
            min-width: 159px;
            padding: 16px 5px 16px;
        }
    }
}

.btn {
    border: 0 !important; 
    border-radius: 0 !important;
    display:inline-block !important;
    vertical-align:top !important;
    padding: 10px 25px !important;
    transition: background 0.25s linear 0s !important;

    &:hover {
        background: #303c65;
    }
}

.table-responsive {
    color: #1e252e;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 25px;
    border: 1px solid #c6c6c6;

    @include media('>=desktop') {
        margin: 0 0 73px;
    }

    .table {
        margin: 0;
    }

    thead {
        background: $blue;
        color: $white;
        font-family: $font-face-primary;
        text-transform: capitalize;

        >tr {
            >th {
                font-size: 16px;
                line-height: 18px;
                padding: 19px 15px;
                width: calc(100% / 4);
                border: 0 !important;

                &:first-child {
                    width: 20%;
                    padding: 19px 29px;
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-child(2n) {
                background: #eee;
            }
        }
    }

    td {
        white-space: normal;
         font-size: 16px;
        line-height: 18px;
        padding: 16px 15px !important;
        width: calc(100% / 4);
        border:  0 !important;

        &:first-child {
            width: 20%;
            padding: 15px 29px !important;
        }
    }
}

.hgroup-block {
    padding: 0 0 25px;

     @include media('>=desktop') {
        padding: 78px 0 25px;
     }

    strong {
        display: block;
        margin: 0 0 15px;
        font-weight: 400;

        &.h1 {
            @include media('>=desktop') {
                margin: 0 0 55px;
            }
        }

        @include media('>=desktop') {
            margin: 0 0 63px;
        }
    }
}

.two-columns {
    @include media('>=desktop') {
        padding: 24px 0 0;
        margin: 0 0 -27px;
    }

    h3 {
        color: #c63d2d;
        margin: 30px 0 4px;

        @include media('>=desktop') {
            font-size: 20px;
        }
    }

    .col-md-11 {
        @include media('>=desktop') {
            padding-right: 24px;
        }
    }

    .sidebar {
        @include media('>=desktop') {
            padding: 0 65px 0 0;
        }
    }

    .title-text {
        background: none;
        text-transform: uppercase;
        color: #2d2d2d;
        border-bottom: 3px solid #bd2924;
        padding: 8px 0;

        @include media('>=desktop') {
            padding: 21px 0 21px;
            font-size: 24px;
            line-height: 25px;
            margin: 0 0 35px;
        }
    }
}