.digistorm-mobile-calendar{
  @media (min-width: 768px){
    display: none;
  }
  .dsmcal-controls:before{
    // nav bar colour
    background-color: #132C6C; 
  }
  .dsmcal-content .dsmcal-day .dsmcal-label .dsmcal-label-total{
    // regular event colour
    background-color: rgba(200,0,0,0.7);
  }
  .dsmcal-content .dsmcal-day .dsmcal-events .dsmcal-event{
    // regular event colour
    border-color: rgba(200,0,0,0.7);
  }
  .dsmcal-content .dsmcal-day .dsmcal-multiday-events .dsmcal-event.dsmcal-has-multiday, .dsmcal-content .dsmcal-day .dsmcal-label .dsmcal-label-multimarker{
    // multi-day event colours
    &.dsmcal-marker-0{
        border-color: darkgreen;
    }
    &.dsmcal-marker-1{
        border-color: blue;
    }
    &.dsmcal-marker-2{
        border-color: pink;
    }
    &.dsmcal-marker-3{
        border-color: green;
    }
    &.dsmcal-marker-4{
        border-color: purple;
    }
    &.dsmcal-marker-5{
        border-color: orange;
    }
    &.dsmcal-marker-6{
        border-color: aqua;
    }
  }

  margin: 10px 0 20px;
  width: 100%;
  overflow: hidden;
  .dsmcal-controls{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    color: white;
    &:before{
      content: '';
      display: block;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    div.dsmcal-current-week{
      z-index: 1;
      width: 100%;
      text-align: center;
      cursor: pointer;
      .dsmcal-month-display{
        display: none;
      }
      position: relative;
      padding-top: 4px;
      &:before, &:after{
        content: '';
        position: absolute;
        width: 6px;
        height: 1px;
        background-color: #888;
        transition: transform 0.1s;
        right: 50%;
        bottom: -8px;
        margin-right: -6px;
        transform-origin: 0px center;
        //opacity: 0.3;
      }
      &:before{
        transform: translateY(-50%) translateY(1px) rotate(225deg);
      }
      &:after{
        transform: translateY(-50%) translateY(1px) rotate(-45deg);
      }
    }
    button{
      z-index: 1;
      cursor:pointer;
      background: none;
      margin: 2px;
      height: 56px;
      width: 56px;
      border: none;
      user-select: none;
      &:before{
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border: 0 solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(-45deg);
        margin-top: -5px;
        margin-left: -6px;
      }
      &.dsmcal-prev-week{
        &:before{
            transform: rotate(135deg);
        }
      }
    }
    position: relative;
    z-index: 2;
    &.dsmcal-shownav{
      .dsmcal-monthnav{
        margin-top: 0%;
      }
      .dsmcal-current-week{
        &:before{
            transform: translateY(-50%) translateY(-2px) rotate(135deg);
        }
        &:after{
            transform: translateY(-50%) translateY(-2px) rotate(45deg);
        }
        .dsmcal-week-range{
          display: none;
        }
        .dsmcal-month-display{
          display: block;
        }
      }
    }
    .dsmcal-monthnav{
      transition: margin 0.3s;
      margin-top: -100%;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: black;
      color: white;
      z-index: -1;
      padding: 20px 0;
      .dsmcal-this-week{
        display: table;
        margin: auto;
        margin-top: 10px;
        font-size: 14px; text-align: center;
        padding: 5px;
        &.dsmcal-is-this-week{
          background-color: rgba(127,127,127,0.5);
          border-radius: 4px;

        }
      }
      table{
        margin: auto;
        tr{
          display: flex;
          width: 216px;
          &.dsmcal-selected-week{
            background-color: rgba(127,127,127,0.5);
            border-radius: 4px;
          }
          td{
            padding: 5px;
            width: 30px;
            text-align: center;
            &.dsmcal-last-month, &.dsmcal-next-month{
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  @keyframes dsmcal-rotate{
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }

  .dsmcal-content{
    .dsmcal-loading{
      width: 100%;
      height: 300px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        height: 30px;
        width: 30px;
        margin-left: -15px;
        margin-top: -15px;
        border: 0 solid black;
        border-width: 1px 0 0 0;
        border-radius: 50%;
        animation: dsmcal-rotate 1s linear infinite;
      }
    }
    .dsmcal-empty{
      text-align: center;
      margin: 40px;
      height: 300px;
    }
    .dsmcal-day{
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        transition: border-color 0.1s;
        margin-top: -1px;
        &:first-child{
            //border-top: none !important;
        }
        &.dsmcal-day-today{
          .dsmcal-label{
            background-color: rgba(255,0,0,0.1);
          }
        }
        &:first-child{
            margin-top: 20px;
        }
        position: relative;
        .dsmcal-label{
          font-size: 16px;
          margin: 0px 0;
          cursor: pointer;
          user-select: none;
          padding: 15px 15px;
          display: flex;
          align-items: center;
          background-color: #eee;
          div{
            display: inline-block;
            padding: 3px;
          }
          color: black;
          position: relative;
          font-weight: normal;
          .dsmcal-label-total{
            color: white;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            text-align: center;
            position: absolute;
            right: 45px;
            top: 9px;
            display: none;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            &.dsmcal-show-total{
                display: inline-flex;
                //display: none;
            }
          }
          .dsmcal-label-weekday{
            //width: 130px;
            display: block;
            padding: 0;
            margin: 0;
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            opacity: 0.8;
          }
          .dsmcal-label-date{
            line-height: 12px;
            padding: 0;
            margin: 0;
            font-size: 12px;
            padding-left: 8px;
            padding-top: 2px;
            .dsmcal-label-day, .dsmcal-label-month{
              color: #7D7D7D;
            }
            .dsmcal-label-day{
              padding-left: 0px;
            }
          }
          .dsmcal-label-multimarker{
            position: absolute;
            top: 0px;
            right: 30px;
            margin-right: 40px;
            width: 0px;
            height: 0px;
            border-width: 17px 9px 17px 0;
            border-style: solid;
            opacity: 0.5;
            //border-radius: 50%;
            //display: none;
            &.dsmcal-marker-1{
                right: 40px;
            }
            &.dsmcal-marker-2{
                right: 50px;
            }
            &.dsmcal-marker-3{
                right: 60px;
            }
            &.dsmcal-marker-4{
                right: 70px;
            }
            &.dsmcal-marker-5{
                right: 80px;
            }
            &.dsmcal-marker-6{
                right: 90px;
            }
          }
          &:before, &:after{
            content: '';
            position: absolute;
            width: 8px;
            height: 2px;
            background-color: #B9B9B9;
            transition: transform 0.1s;
            right: 20px;
            top: 50%;
            transform-origin: 1px center;
          }
          &:before{
            transform: translateY(-50%) translateY(4px) rotate(225deg);
          }
          &:after{
            transform: translateY(-50%) translateY(4px) rotate(-45deg);
          }
        }
        
        .dsmcal-events, .dsmcal-multiday-events{
          .dsmcal-event{
            border: 0px solid #eee;
            border-collapse: collapse;
            padding: 0 16px;
            visibility: hidden;
            max-height: 0px;
            overflow: hidden;
            box-sizing: border-box;
            padding-left: 10px;
            padding-right: 36px;
            border-left: 2px solid rgba(255,0,0,0.7);
            position: relative;
            cursor: pointer;

            &:before, &:after{
              content: '';
              position: absolute;
              width: 6px;
              height: 1px;
              background-color: #888;
              transition: transform 0.1s;
              right: 26px;
              top: 14px;
              margin-right: -6px;
              transform-origin: 0px center;
              opacity: 0.5;
            }
            &:before{
              transform: translateY(-50%) translateY(1px) rotate(225deg);
            }
            &:after{
              transform: translateY(-50%) translateY(1px) rotate(-45deg);
            }
          }
        }
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
        &.dsmcal-day-expanded{
          
          padding-bottom: 5px;
          .dsmcal-label{
            &:before{
                transform: translateY(-50%) translateY(0px) rotate(135deg);
            }
            &:after{
                transform: translateY(-50%) translateY(0px) rotate(45deg);
            }
            .dsmcal-label-multimarker{
              display: none;
            }
            .dsmcal-label-total{
              display: none;
            }
          }
          .dsmcal-multiday-events{
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .dsmcal-event{
            visibility: visible;
            max-height: 100vh;
            margin: 10px 0;
            margin-left: 47px;
            padding-top: 8px;
            padding-bottom: 8px;
            .dsmcal-event-title{
              font-size: 14px;
            }
            .dsmcal-event-time{
                font-size: 12px;
                margin-top: 5px;
                opacity: 0.7;
                display: none;
            }
            .dsmcal-event-body{
              font-size: 12px;
              margin: 14px 0 5px;
              display: none;
            }

            &.dsmcal-has-multiday{
              //border-left-width: 0;
              //border-right-width: 2px;
              //margin: 4px 44px 0 0;
              //margin: 4px 44px 0 47px;
              //padding: 0 10px 0 10px;
              opacity: 0.7;
              //text-align: right;
              padding-top: 0px;
              padding-bottom: 0px;
              .dsmcal-event-title{
                font-size: 12px;
                
              }
              .dsmcal-event-body{
                display: none;
              }
              .dsmcal-event-time{
                display: none;
              }
            }
            &.dsmcal-event-expanded{
              .dsmcal-event-time, .dsmcal-event-body{
                display: block;
              }
              &:before{
                  transform: translateY(-50%) translateY(-2px) rotate(135deg);
              }
              &:after{
                  transform: translateY(-50%) translateY(-2px) rotate(45deg);
              }
            }
            .dsmcal-event-body:empty{
              display: none !important;
          }
        }
      }
    }
  }
}