@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?1y8t8t');
    src:    url('../fonts/icomoon.eot?1y8t8t#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?1y8t8t') format('truetype'),
        url('../fonts/icomoon.woff?1y8t8t') format('woff'),
        url('../fonts/icomoon.svg?1y8t8t#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\e90f";
}
.icon-achievement:before {
    content: "\e910";
}
.icon-facilities:before {
    content: "\e911";
}
.icon-community:before {
    content: "\e912";
}
.icon-phone-book:before {
    content: "\e913";
}
.icon-arrows:before {
    content: "\e914";
}
.icon-close:before {
    content: "\e901";
}

