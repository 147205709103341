#calendar-mobile{
    .digistorm-mobile-calendar{
        .dsmcal-controls{
            background-color: #00234C;
        }
    }
}

#calendar{
    @media (max-width: 767px){
        display: none;
    }
}

#calendar.fc{
  width: 100%;


  position: relative;


  display: none;
  @media (min-width: 768px){
    display: block;
  }


  @keyframes loadingrotate{
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }

  &:before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 60px;
    width: 60px;
    margin-left: -30px;
    margin-top: -30px;
    border: 0 solid black;
    border-width: 1px 0 0 0;
    border-radius: 50%;
    animation: loadingrotate 1s linear infinite;
    opacity: 0;
    border-color: #00234C;
    z-index: -1;
  }
  &.loading{
    &:before{
      opacity: 1;
      z-index: 999;
    } 
  }


  td{
    padding: 0;
  }
  td.fc-header-center {
    border-top:0px !important;
  }

  .fc-widget-header{
    text-align: center;
  }

  .fc-header{
    text-align: center;
    margin: 60px 0 60px;
    // background-color: #00234C;
    padding: 10px;
    color: #00234C;
    h2{
      margin: 0;
      color: #00234C;
    }
    tr{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .fc-header-left{
      text-align: left;
    }
    .fc-header-right{
      text-align: right;
    }

    .fc-button{
      cursor:pointer;
      padding: 15px;
      color: white;
      margin: 2px;

      user-select: none;
    }
  }


  .fc-widget-content{
    padding: 0 5px;
    vertical-align: top;

  }

  .fc-day-number{
    text-align: right;
    font-size: 20px;
    padding: 5px;
    padding-top: 10px;
    color: #555;
  }

  .fc-today{
    // background-color: #00234C;
    .fc-day-number{
      //color: #de221e;
    }
  }

  .fc-other-month{
    .fc-day-number{
      color: #ddd;
    }
  }

  .fc-event{
    font-size: 13px;
    //font-weight: bold;
    line-height: 16px;
    padding: 8px 0 8px 10px;
    border: 1px solid #f9f9f9;
    box-sizing: content-box;
    cursor: pointer;
    overflow: hidden;

    background-color: #00234C !important;
    color: #fff;

    .fc-event-title{
      font-weight: 500;
    }

    &.popup-active{
      z-index: 999 !important;
    }

    div.cal-popup{
      position: absolute;
      bottom: calc(100% + 12px);
      left: 50%;

      background-color: #fff !important;
      color: black !important;
      padding: 10px 30px;
      visibility: hidden;
      width: 320px;

      z-index: 999 !important;

      cursor: initial;
      user-select: text;

      transform: translateX(-50%) translateY(0%)  scale(1);
      //transform: translateX(-50%) translateY(100%) scale(0);

      transition: transform 0.3s, visibility 0.3s;

      border: 2px solid #eee;



      &.active{
        visibility: visible;
        transform: translateX(-50%) translateY(0%)  scale(1);

        animation: popup-fade-in 0.3s;
      }
      &:not(.active){
        animation: popup-fade-out 0.3s;
      }

      &:after{
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-top-color: #eee;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

      }

      &.left{
        margin-left: 120px;
        &:after{
          margin-left: -120px;
        }
      }

      &.right{
        margin-left: -120px;
        &:after{
          margin-left: 120px;
        }
      }

      &.top{
        bottom: auto;
        top: calc(100% + 12px);
        &:after{
          border-top-color: transparent;
          border-bottom-color: #eee;
          top: auto;
          bottom: 100%;
        }
        &.active{
          animation: popup-fade-in-top 0.3s;
        }
        &:not(.active){
          animation: popup-fade-out-top 0.3s;
        }
      }

      h2{
        line-height: 32px;
      }

      p{
        font-size: 16px;
        line-height: 20px;
      }

      small{
        display: block;
        font-size: 12px;
        margin-bottom: 15px;
      }

      button.close{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        border: none;
        outline: none;
        box-shadow: none;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 35, 76,.2);
        color: white;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover{
          background-color: rgba(0, 35, 76,.8);
        }
      }
    }


    .fc-event-time{
      padding-right: 5px;
    }


    transition: background 0.3s, z-index 0.3s;
    div{
      transition: background 0.3s;

      background-color: #00234C !important;
      color: #fff !important;
    }

    &:hover{
      background-color: rgba(0, 35, 76,.8) !important;

      //border-color: black;

      div{
        background: none !important;
      }
      div.cal-popup{
        background-color: #fff !important;
      }
    }

    overflow: initial;


  }



  .fc-content{
    tbody {
      //background-color: #f9f9f9;
    }
  }

  .fc-agenda-axis{
    display: none;
  }
}



@keyframes popup-fade-in{
  from{
    opacity: 0;
    transform: translateX(-50%) translateY(50px);
  }
  to{
    opacity: 1;
    transform: translateX(-50%) ;
  }
}

@keyframes popup-fade-out{
  from{
    opacity: 1;
    transform: translateX(-50%) ;
    visibility: visible !important;
  }
  to{
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
    visibility: hidden !important;
  }
}

@keyframes popup-fade-in-top{
  from{
    opacity: 0;
    transform: translateX(-50%) translateY(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(-50%) ;
  }
}

@keyframes popup-fade-out-top{
  from{
    opacity: 1;
    transform: translateX(-50%) ;
    visibility: visible !important;
  }
  to{
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
    visibility: hidden !important;
  }
}
