.container-fluid {

	@media screen and (min-width:992px){
		padding-left:50px;
		padding-right:50px;
	}
}

.quote {
  .quote-content {
        padding: 32px 20px 32px 85px;
        font-size: 20px;
        font-style: italic;
        position: relative;
        font-weight: 400;
      &:before{
        content: '\201C';
        position: absolute;
        top: 18px;
        left: -10px;
        z-index: 1;
        font-family: Arial,Helvetica,sans-serif;
        font-size: 140px;
        line-height: 140px;
        font-weight: bold;
        color: #00234C;
      }
  }
  .quote-text{
    color: #0b1838;
    font-size: 20px;
  }
  .quote-byline {
      font-size: 15px;
  }
}

.gallery.lightbox {
	margin:30px 0px;
    position: relative !important;
    z-index: 1 !important;
    a{
      margin:0px;
    }
}
.gallery.carousel .slick-slide {
    opacity: 0.5;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -ms-transform: scale(0.95);
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
}
.gallery.carousel .slick-slide.slick-center {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.gallery.lightbox img {
    width: 135px;
    padding-right: 10px;
    margin-bottom: 15px;
}
.gallery.lightbox {
  text-align:left;
}
button.slick-arrow {
    position: absolute;
    z-index: 10;
    top: 45%;
    background: #00234C;
    color: #fff;
    border: 0px !important;
    height: 40px !important;
    line-height: 40px !important;
}
button.slick-arrow {
    width: 36px;
    height: 38px;
}
button.gallery-left.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 45%;
}
button.gallery-right.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 45%;
    right: 0px;
}

.inside-page-heading{
	.btn-primary {
	    color: #fff;
	    background-color: #1e2b58;
	    border-color: #182245;
	    text-transform: uppercase;
	    -webkit-font-smoothing: antialiased;
	    font-size: 18px !important;
	    margin: 20px 0px;
		transition: all 0.5s;
		-moz-transition: all 0.5s; /* Firefox 4 */
		-webkit-transition: all 0.5s; /* Safari and Chrome */
		-o-transition: all 0.5s; /* Opera */
	    &:hover{
    	    background: #00234C;
		    border-color: #00234C;
	    }
	}
}
.form{
	.form__row {
	    width: 80%;
	    margin-bottom:12px;
	    input.text {
		    width: 100%;
		    height: 42px;
		    border: 0px;
		    background: #eee;
            padding-left: 10px;
		}
		textarea.text {
		    border: 0px;
		    background: #eee;
		    width: 100%;
		}
		.form__label label {
		    display: inline-block;
		    max-width: 100%;
		    margin-bottom: 5px;
		    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
		    font-size: 16px;
		    font-weight:normal;
		    // color:#fff;
		}
	}
	.form__note {
	    font-size: 12px;
	    width: 80%;
	    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
	}
}
.form-sidebar{

	margin:25px 0px;

	.form{
		.form__row {
			width:100% !important;
		}
		.btn-primary {
		    color: #fff;
		    background-color: #1e2b58;
		    border-color: #182245;
		    text-transform: uppercase;
		    -webkit-font-smoothing: antialiased;
		    font-size: 18px !important;
		    margin: 20px 0px;
			transition: all 0.5s;
			-moz-transition: all 0.5s; /* Firefox 4 */
			-webkit-transition: all 0.5s; /* Safari and Chrome */
			-o-transition: all 0.5s; /* Opera */
		    &:hover{
	    	    background: #00234C;
			    border-color: #00234C;
		    }
		}
		.btn-lg {
		    padding: 10px 25px !important;
		    margin-top: 15px !important;
		    text-transform: uppercase !important;
		    -webkit-font-smoothing: antialiased;
	        width: 115px !important;
		}
	}
}
.content-area.aside {
    padding: 0px;
}
.inside-page-detailed-blogs{

	.article-content {
	    padding: 20px 0px;
	    margin: 30px 0px;
	    h4 {
		    margin: 0px;
		    padding-bottom: 15px;
		    text-transform: uppercase;
		    margin-top: 20px;
		}
		a.btn.btn-default {
		    margin: 30px 0px;
		}
		img.img-responsive.single-image {
		    width: 100%;
		}
	}
	.news-article-image {
	    float: left;
	    img{
	    	@media screen and (max-width:999px){
				width:100%;
	    	}
	    }
	    @media screen and (max-width:999px){
			float:none;
		}
	}
	.news-article-text {
	    float: left;
	    width: 60%;
		@media screen and (min-width:1000px) and (max-width:1290px) {
			width:45%;
		}
		@media screen and (min-width:1291px) and (max-width:1400px) {
			width:55%;
		}
		@media screen and (max-width:999px){
			width:100%;
			padding-left: 0px;
			padding-top:30px;
		}
	    padding-left: 30px;
	}
	.article-text {
	    padding-top: 20px;
	}
	ul.article_category {
	    width: 100%;
	    padding: 0px;
	    li {
		    list-style: none;
		    float: left;
		    margin-right: 5px;
		}
		&.blog_detailed {
			margin:15px 0px;
		}
	}
	.btn-primary.read-button {
	    color: #fff;
	    background-color: #1e2b58;
	    border-color: #182245;
	    text-transform: uppercase;
	    -webkit-font-smoothing: antialiased;
	    font-size: 18px !important;
		transition: all 0.5s;
		-moz-transition: all 0.5s; /* Firefox 4 */
		-webkit-transition: all 0.5s; /* Safari and Chrome */
		-o-transition: all 0.5s; /* Opera */
	    &:hover{
    	    background: #00234C;
		    border-color: #00234C;
	    }
	    &.btn-lg {
		    padding: 10px 20px !important;
		    margin-top: 15px !important;
		    text-transform: uppercase !important;
		    -webkit-font-smoothing: antialiased;
	        width: 140px !important;
            min-width: 150px;
		}
	}
	&.detail-page{
		padding-bottom:30px;
		.single-image {
		    padding: 15px 0px !important;
		}
	}
}
.inner-home .img-holder{
	padding-left:0px !important;
}
// Start Search Page Style//
.inside-search-page{
	.search-wrapper {
	    padding: 10px;
	    background: #f2f2f2;
	    border: 10px solid #f2f2f2;
	}
	form{
		margin:0px !important;
		display: flex;
    	flex-wrap: wrap;
	}
	input.form-search {
		width: 75%;
	    border: 1px solid #f2f2f2;
	    padding: 10px;
	    font-size: 20px;
	}
	button.search-web {
	    padding: 10px;
	    width: 24%;
	    border: 1px solid #f2f2f2;
	    background: #3697D2;
	    font-size: 20px;
	    color: #fff !important;
	    &:hover{
			    background: #284E94;
	    }
	}
	a {
	    color: #212121 !important;
	    text-decoration: none;
	    &:hover{
	    	background: transparent;
		    color: #888;
		}
	}
    .search-article-listing {
        .search-read-more {
            color: #DC241F !important;
        }
    }
	.search-article-listing:hover {
	    text-decoration: underline;
	}
	button.btn.btn-primary {
	    padding: 10px !important;
	    margin: 0px !important;
	}
}

.header-block{
	.info-holder{
		li{
			a{
				color:#fff;
				&:hover{
					color:#92aaeb;
				}
			}
		}
	}
}

.home-banner-image{

	.banners{
		margin:0px;
	}
	.slick-dots{
	    position: absolute;
	    bottom: 0px;
	    left: 0;
	    right: 0;
	    padding: 0px;
	    text-align: center;
	    bottom: 8px;

	    @include media('>=tablet') {
	        max-width: 80%;
	    }

	    @include media('>=desktop') {
	        bottom: 19px;
	        max-width: 65.5%;
	    }

	    li {
	        list-style: none;
	        display: inline-block;
	        margin: 0 3px;
	        width: 12px;
	        height: 12px;
	        font-size:0;
	        line-height:0;
	        background-color:#253967;
	        border-radius: 100%;
	        cursor: pointer;

	        &:hover {
	            background-color: $white;
	        }

	        button {
	            background: none;
	            border: none;
	            outline: none;
	            background-color: white;
	            width: 18px;
	            height: 18px;
	            border-radius: 100%;
	            color: transparent;
	            background: none;
	        }
	        &.slick-active,
	        &.active {
	            background-color: $white;

	            button {
	                background: none;
	                cursor: pointer;
	            }
	        }
	    }
	}
}
a.link-media{
	cursor: pointer;
}
.news{
	.label, .date, h3{
		text-transform:uppercase;
	}
}
.video-wrapper{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 231px;
	iframe{
		width:100% !important;
		display: block;
	}
	.duration{
		position: absolute;
	    right: 0;
	    bottom: 0px;
	    padding: 4px;
	    text-align: center;
	    width: 64px;
	    height: 32px;
	    font-size: 18px;
	    line-height: 23px;
	    color: #fff;
	    background: #00234c;
	    z-index: 2;
	}
}
.yt-player2 {
	width: 100%;
	height: 231px;
	background-size: cover;
	background-repeat: no-repeat;
	cursor:pointer;
	&:before {
	    border-top: 25px solid transparent;
	    border-bottom: 25px solid transparent;
	    border-left: 42px solid #fff;
	    content: "";
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    z-index: 3;
	    -webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	}
	&:after {
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    content: "";
	    background: rgba(0, 34, 76, .5);
	    z-index: 1;
	    -webkit-transition: background .25s linear 0s;
	    transition: background .25s linear 0s;
	}
	&:hover:after{
		background: rgba(0, 34, 76, .7);
	}
}
.videos-block{
	.col-xs-6.col-md-3:nth-child(3n) {
	    clear: both;
	}
	h3{
		width:100%;
	}
	.col-xs-6.col-md-3{
		@media screen and (max-width:465px){
			width:100%;
		}
		@media screen and (max-width:991px){
			margin-bottom:20px;
		}
	}
}
.inner .btn.btn-default {
    text-transform: uppercase;
    margin: 15px 0px;
    min-width: 159px;
    padding: 16px 5px 16px;
    font-size: 14px;
    font-family: "gotham book", Helvetica, Arial, sans-serif;
}
blockquote {
  font-size: 24px;
  line-height: 36px;
  font-style: italic;
  position: relative;
  padding: 30px 10% 30px 13%;
  border-left: 0px;

  @include media('>=desktop') {
    padding: 43px 10% 49px 13%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 45px;
    left: 10%;
    bottom: 30px;
    border-left: 3px solid #00234C;
  }
}
.inner{
	padding-bottom:30px;
	h2{
		font-size:25px;
	}
	h3{
		font-size:23px;
	    color: #1d345d;
	    margin-bottom:25px;
	}
	h4{
		font-size:21px;
	    color: #1d345d;
	    margin-bottom:25px;
	}
	h5{
		font-size:18px;
	    color: #1d345d;
	    margin-bottom:25px;
	}
	h6{
		font-size:17px;
	    color: #1d345d;
	    margin-bottom:25px;
	}
}
.table-responsive {
	margin: 0 0 25px;
}
.btn.btn-default.btn-sm {
    margin: 0px !important;
    padding: 6px 8px;
    min-width: auto !important;
    font-size: 12px;
}

//Calendar Styles
.fc-button {
	background-color: #00234C !important;
    border: 2px solid #00234C !important;
    height: 41px !important;
    &:hover{
    	background: #303c65 !important;
    }
}
.fc-event{
	background-color: #00234C !important;
	.fc-event-skin{
		background: #00234C !important;
	    &:hover{
	    	background: #303c65 !important;;
	    }
	}
    &:hover{
    	background: #303c65 !important;
    }
}
.videos-block .label,.news-home-block .label{
    padding: 0px;
}
.news-home-block{
	img{
		width:100% !important;
	}
}
.event-newsletter-wrapper{
	.events{
		padding:0px;
	}
}
.mobile-tab-view{
	@media screen and (min-width:768px){
		display:none;
	}
}
.tlc-calendar .table > tbody > tr > td{
	padding-top:30px;
}
.second-level .dropdown{
	padding:0px !important;
	a{
		font-size:14px !important;
		padding-left:45px !important;
	}
	&:before{
		border-left:0px !important;
		border-right:0px !important;
	}
}
.nav-active{
    .nav{
        @media screen and (max-width:767px){
        	a{
        		display: block !important;
    		    font-size: 15px !important;
    		    z-index: 1;
		        padding: 10px !important;
        	}
			li.first-level.collapsed ul.dropdown{
				display:none !important;
			}
			li.first-level.collapsed-in ul.dropdown{
				display:block !important;
			}
			li:hover > a {
			    // background: none;
			    background: #3d5aa7;
			}
			li{
				border-bottom:0px;
			}
			li ul {
			        background: #2c3557;
				    width: 100%;
				    padding: 8px 0px;
			    li {
				    padding: 0px;
				    a {
					    font-size: 14px !important;
					}
					&:hover a{
						// background:none;
					}
				}
			}
        }
    }
}
.nav{
    @media screen and (max-width:767px){
	    padding-top: 12px !important;
    	a{
			display: block !important;
		    font-size: 15px !important;
		    z-index: 1;
	        padding: 10px !important;
    	}
		li.first-level.collapsed ul.dropdown, li.first-level.collapsed-in ul.dropdown {
			display:none !important;
		}
		li{
			border-bottom:0px !important;
		}
		li:hover > a {
		    background: #3d5aa7;
		}
		li ul {
		        background: #2c3557;
			    width: 100%;
			    padding: 8px 0px;
		    li {
			    padding: 0px;
			    a {
				    font-size: 14px !important;
				}
				&:hover a{
					// background:none;
				}
			}
		}
    }
}
span.fa.arrow-selector{
	@media screen and (min-width:768px){
		display:none;
	}
    position: absolute;
    right: 15px;
    top: 0px;
    color: #fff;
    width: 50px;
    text-align: right;
    height: 40px;
    line-height: 40px;
    z-index: 10;
}
.logo.inside-page-logo {
	@media screen and (min-width:768px){
	    display: none;
	}
}
.logo-block{
	@media screen and (max-width:767px){
	    display: none;
	}
}
span.icon-close.close{
	text-shadow:none;
	color:#6983c8;
}
.newsletters-page-section{
    .newsletter-content{
        display: block;
        padding: 16px 20px;
        border: 0px solid rgba(0,0,0,0.1);
        border-width: 1px 0 0;
        .heading{
            margin-bottom: 8px;
        }
        .newsletter-issue{
            line-height: 18px;
            font-size: 20px;
            display: block;
        }
        .newsletter-date{
            opacity: 0.7;
            line-height: 18px;
        }
        &:hover{
            background-color: rgba(0,0,255,0.02);
            &:before{
                right: 25px;
            }
        }
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: 0px solid black;
            border-width: 2px 2px 0 0;
            right: 30px;
            top: 50%;
            opacity: 0.5;
            margin-top: -10px;
            transform: rotate(45deg);
            transition: right 0.2s;
            transition-timing-function: cubic-bezier(.48,.16,.4,2);
        }
    }
}
.newsletter-content.newsletter-page-clone{
    display: none;
}
.events-page-section{
    .event-content{
        padding: 15px 0;
        .heading{
            margin-bottom: 10px;
        }
        .event-date{
            opacity: 0.7;
        }
    }
}
.event-content.event-page-clone{
    display: none;
}
.publication-content{
    padding: 20px 0;
    display: flex;
    @media (max-width: 480px){
        flex-direction: column;
        .publication-image img{
            width: 100%;
        }
    }
    .publication-text{
        padding: 30px;
        .heading{
            margin-bottom: 20px;
        }
    }
    .publication-image{
	    img {
		    max-width: initial !important;
		}
	}
}
.nav-active #wrapper:after {
    opacity: 1;
    visibility: visible;
}
#wrapper:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(1, 26, 65, 0.8);
    content: '';
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in 0s;
    z-index: 2;
    height: 100%;
}
body.modal-open {
    padding-right: 0px !important;
    overflow: auto !important;
}
.events .container-fluid{
	position: relative;
	span.month {
		margin-left: 5px;
	}
}
a.calendar-homepage {
    position: absolute;
    right: 50px;
    display: block;
    color: #00234C;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    &:hover{
	    color: #DC241F;
	    transition: color 0.25s linear;
    }
}
.inside-page-detailed-videos{
	.videos-content{
		padding: 20px 0px;
	    margin: 30px 0px;
		.video-image-wrapper.video-wrapper{
			width:247px !important;
			@media screen and (max-width:991px){
				width: 100% !important;
			}
			float:left;
			.yt-player2{
				width:247px;
			    background-position: left center;
				@media screen and (max-width:991px){
					width: 100% !important;
				}
			}

		}
		.videos-text {
		    float: left;
		    width: 56%;
		    padding-left: 30px;
		    @media screen and (max-width:991px){
				width: 100% !important;
				padding-left:0px;
			}
		    .label{
		    	padding:0px;
		    }
		    h2{
				text-transform: capitalize;
			    line-height: 35px;
			    margin-top: 5px !important;
		    }
		}
	}
}
// Prep calculator
.prep-calc-wrap {
    background: #f2f2f2;
    padding: 15px;
	.styled-select.blue.semi-square {
		display:inline-block;
		margin-right:12px;
	}
}
.blue select option {
	// color:#333 !important;
}
.blue select{
	width: 150px  !important;
    height: 40px !important;
    border: 1px solid #00234C !important;
    padding: 10px !important;
    margin-bottom: 10px !important;
}

.prep-calc-output {
	position:relative;
    padding: 15px 0px;
	// height:66px;
	p{
		margin-bottom: 10px !important;
	}
}

.output-p-year,
.output-p-year7 {
	display:inline-block;
	transition:all 0.5s;
	transform:translate(-200%, 0%);
	opacity:0;
	* {
		transition:all 0.5s;
	}
	&.off-screen {
		transform:translate(200%, 0%) !important;
		transition:all 0.5s !important;
		opacity:0 !important;
	}
	&.on-screen {
		transform:translate(0%, 0%);
		transition:all 0.5s;
		opacity:1;
	}
	.prep-calc-year-output,
	.prep-calc-year-7-output {
		font-weight: bold;
	}
}
.gallery-section-wrapper{
	min-height:600px;
	@media screen and (min-width:768px){
		position: relative;
		display:flex;
		.gallery-block{
			position: absolute;
			bottom:0px;
			margin: 0px 15px 0px 0px;
		}
	}
}
.home-banner-image{
	.banners{
		display: none !important;
		&:first-child{
			display: block !important;
		}
	}
	&.slick-initialized{
		.banners{
			display: block !important;
		}
	}
}
// .ui-tabs-vertical { width: 55em; }
// .ui-tabs-vertical .ui-tabs-nav { padding: .2em .1em .2em .2em; float: left; width: 12em; }
// .ui-tabs-vertical .ui-tabs-nav li { clear: left; width: 100%; border-bottom-width: 1px !important; border-right-width: 0 !important; margin: 0 -1px .2em 0; }
// .ui-tabs-vertical .ui-tabs-nav li a { display:block; }
// .ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active { padding-bottom: 0; padding-right: .1em; border-right-width: 1px; }
// .ui-tabs-vertical .ui-tabs-panel { padding: 1em; float: right; width: 40em;}
.matrix-tabs{
	padding:30px 0px;
	display: flex;
	flex-wrap: wrap;
	@media screen and (max-width:565px){
		border:2px solid #f2f2f2;
		padding:15px !important;
		margin-bottom:15px;
	}
	.display-flex{
		width:60%;
		@media screen and (max-width:565px){
			width: 100% !important;
		}
	}
	ul{
		padding:0px;
		@media screen and (min-width:767px) and (max-width:991px){
			display: flex;
		    flex-wrap: wrap;
		}
		// @media screen and (max-width:565px){
		// 	display: flex;
		//     flex-wrap: wrap;
		// }
		li{
			list-style:none !important;
			width: 100%;
			margin:0px;
			@media screen and (min-width:767px) and (max-width:991px){
				width:50%;
				padding:0px 5px;
			}
			&:last-child{
				a{
					border-bottom:0px;
					@media screen and (min-width:767px) and (max-width:991px){
						border-bottom:1px solid #f2f2f2;
					}
					// @media screen and (max-width:565px){
					// 	width:50%;
					// 	padding:0px 5px;
					// }

				}
			}
			&.active{
				a{
					color: #00234C;
				}
			}
		}
		&.display-flex{
			width:30%;
			@media screen and (min-width:767px) and (max-width:991px){
				width:100% !important;
			}
		}
		@media screen and (max-width:565px){
			border-bottom:2px solid #f2f2f2;

		}
	}
	.tab{
		opacity: 0;
		height: 0px;
		display: none;
		transition: display 0.3s;
		padding-left:50px;
		@media screen and (min-width:767px) and (max-width:991px){
			padding-left:0px;
			padding-top:10px;
			width:100%;
		}
		@media screen and (max-width:565px){
			padding-left:0px;
			padding-top:10px;
			width:100%;
		}
		&.active{
			opacity: 1;
			height: 100%;
			display: block;
			transition: display 0.3s;
		}
	}
	h2.tab-heading {
	    text-transform: uppercase;
	    margin: 0px !important;
	    cursor: pointer;
	    a {
		    display: block !important;
		    width: 100%;
		    height: 100%;
		    padding: 20px 0px;
		    cursor: pointer;
		    border-bottom:1px solid #f2f2f2;
		    @media screen and (max-width:565px){
				padding: 15px 0px !important;
				font-size: 18px;
			}
		}
	}
}
.facebook-video-wrapper {
    height: 100%;
    background: rgb(0, 0, 0);
    .fb_iframe_widget {
	    display: inline-block;
	    position: relative;
	    margin: 8.5% 0px !important;
	}
}
a.button-tabs {
    display: block;
    text-decoration: underline;
    &:hover{
    	text-decoration: underline;
    }
}
.promoBlock, .promoBlock * {
    transition: all ease-in .2s;
}
.promoBlock {
    height: 350px;
    position:relative;
    margin: 0 0 25px;
    overflow: hidden;

    @include  media('>=tablet') {
        height: 490px;
    }

    @include media('>=desktop') {
        height: 606px;
    }

    .promo-banner-wrapper {
		// max-height: 606px;
		height:350px;
	    overflow: hidden;
	    height: 100%;
	    @include  media('>=tablet') {
            height: 490px;
        }

        @include media('>=desktop') {
            height: 606px;
        }
        .promo-banner-caption {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: #00234C;
			height: 52px;
			line-height: 50px;
			padding: 0 8px;
			color: #fff;
			.promo-banner-hidden{
				position: absolute;
			    padding: 0 6px;
			    top: 50%;
			    transform: translateY(-50%);
			    line-height: 1.5!important;
			    opacity: 0;
			}
			.promo-banner-chevron {
			    position: absolute;
			    right: 12px;
				i {
				    transform: rotate(-90deg);
				    -webkit-transform: rotate(-90deg);
				}
			}
		}

        @include  media('>=tablet') {
            height: 490px;
        }

        @include media('>=desktop') {
            height: 606px;
        }

        .promo-banner {
        	margin-top:0px;
        	min-height: 350px;
            background-size:cover;
            background-position:center center;
            background-repeat:no-repeat;
            @include  media('>=tablet') {
	            height: 490px;
	        }

	        @include media('>=desktop') {
	            height: 606px;
	        }
        }
    }
    .container {
        position:relative;
    }
    &:hover{
    	.promo-banner-caption{
    		height:100%;
    		background: rgba(0, 35, 76, 0.7);
    		.promo-banner-chevron {
	    		i{
	    			transform: rotate(0);
				    -webkit-transform: rotate(0);
	    		}
	    	}
    	}
    	.promo-banner-hidden {
    		opacity:1 !important;
    	}
    	.promo-banner{
    		margin-top:50px;
    	}
    }
}
.intro-text.news-summary{
	font-weight:600;
	font-size:20px;
}
.article.full-width.not-found-page {
    text-align: center;
    padding: 50px 0px;
}
.fb-video.fb-video-dummy {
    display: none;
}
.facebook-video-wrapper{
	.fb-video-image {
		width: 100%;
		height: 231px;
		background-size: cover;
		background-repeat: no-repeat;
		cursor:pointer;
		position: absolute;
		top: 0px;
		&:before {
		    border-top: 25px solid transparent;
		    border-bottom: 25px solid transparent;
		    border-left: 42px solid #fff;
		    content: "";
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    z-index: 3;
		    -webkit-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		    transform: translate(-50%, -50%);
		}
		&:after {
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    content: "";
		    background: rgba(0, 34, 76, .5);
		    z-index: 1;
		    -webkit-transition: background .25s linear 0s;
		    transition: background .25s linear 0s;
		}
		&:hover:after{
			background: rgba(0, 34, 76, .7);
		}
	}
}
.videos-block .video-wrapper {
    margin-bottom: 15px;
}
.main.main-wrapper {
    padding-bottom: 50px;
}
.img-responsive.single-image {
    width: 100%;
}
.gallery.carousel{
	padding-bottom:30px;
}
.table-wrappers{
  margin: 15px 0px !important;
  h2.table-heading {
      margin-bottom: 5px !important;
      color: #2a2a2a!important;
      font-weight: 500 !important;
      line-height: 28px !important;
      font-size: 24px !important;
      text-transform: none !important;
  }
  table {
    // border: 1px solid #ccc;
    width: 100% !important;
    margin:0 !important;
    padding:0 !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;
  }

  table tr {
    // border: 1px solid #ddd;
    padding: 5px !important;
  }

  table th, table td {
    padding: 15px !important;
  }
  table td {
    border-bottom: 1px solid #ccc !important;
    font-size: 16px !important;
  }
  table th {
    font-size: 20px !important;
    letter-spacing: 1px !important;
    border-bottom: 3px solid #00234C !important;
    font-weight: 400 !important;
    color: #2a2a2a!important;

  }
  table tr:last-child td {
    border-bottom:0px !important;
  }

  @media screen and (max-width: 600px) {
   h2.table-heading {
	    font-size: 20px !important;
	    line-height: 35px !important;
	    border-bottom: 1px solid #D8D8D8 !important;
	    padding-bottom: 10px !important;
	    color: #393939 !important;
	    letter-spacing: 0 !important;
	    -webkit-font-smoothing: antialiased;
   }
    table  [data-label]{
      font-weight: 400 !important;
      color: #2a2a2a!important;
      font-size: 15px !important;
    }
    .row-text {
        color: #101820 !important;
        font-size: 15px;
    }
    table.one-column-table {

      // &.one-column-table{
        border: 0 !important;

        thead {
          display: none !important;
        }

        tr {
          margin-bottom: 10px !important;
          display: block !important;
          border-bottom: 3px solid #00234C !important;
        }

        td {
          display: block !important;
          text-align: right !important;
          font-size: 13px !important;
          border-bottom: 1px solid #ccc !important !important;
          color: #231f20 !important;
        }

        td:last-child {
          border-bottom: 0 !important;
        }

        td:before {
          content: attr(data-label) !important;
          float: left !important;
          text-transform: uppercase !important;
          font-weight: bold !important;
        }
      }
  }
}

.navbar-alert{
	.container{
		position: relative;
	}
    background-color: #00234C;
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    p{
        margin: 0;
        color: #fff;
    }

    .alert-title{
       font-size: 22px;
    }
    .alert-message, .alert-message p{
        font-size: 14px;
        margin-top: 10px;
    }

    .alert-message{
        // //display: block;
        // cursor: initial;
        // position: absolute;
        // top:100%;
        // left: 50%;
        // transform: translateX(-50%);
        // max-width: 700px;
        // padding-top: 40px;
        // padding-bottom: 40px;
        // width: 80%;
        // &:before{
        //     content: '';
        //     background-color: #005F2C;
        //     position: absolute;
        //     top: 0;
        //     left: -50vw;
        //     right: -50vw;
        //     bottom: 0;
        //     z-index: -1;
        // }
        // a{
        //     color: white;
        //     display: block;
        //     text-align: center;
        //     &:before{
        //         content: '> ';
        //     }
        // }
        // display: none;
        // transition: margin 0.3s;
    }
    &.active.expanded{
        .alert-message{
            display: block;
        }
        &:after{
            transform: scaleY(-1);
        }
    }

    .dismiss-alert{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 30px;
        height: 30px;

        cursor: pointer;

        &:hover{
            opacity: 0.5;
        }

        &:before, &:after{
            content: '';
            position: absolute;
            width: 4px;
            height: 20px;
            top: 15px;
            left: calc(50% - 6px);
            background-color: white;
        }

        &:before{
            transform: rotate(45deg);
        }
        &:after{
            transform: rotate(135deg);
        }
    }

    &.active{
        // position:fixed;
        // top:0px;
        z-index: 10000;
        width: 100%;
        padding:20px;
    }
}
.navbar-alert{
    height:0px;
}
.navbar-alert.active{
    height: 90px;
    overflow: hidden;
    @media screen and (max-width:525px) {
       height:140px;
    }
}
.image-screenshot {
    width: 100px;
    float: left;
}
.newsletter-contents {
    float: left;
    padding-left: 25px;
}
.newsletter-block {
	margin-bottom:0px !important;
	a.newsletter-title {
	    display: block !important;
	    padding: 15px !important;
	    text-decoration: none !important;
	    cursor: pointer !important;
        color: #fff !important;
	    font-size: 16px !important;
	}
	h2.panel-title{
		margin-bottom:0px !important;
	}
	&.panel{
		margin-bottom:0px !important;
	}
	.panel-default {
	    border-color: #ddd !important;
	    margin-bottom: 0px !important;
	    border: 0px !important;
	    padding:0px !important;
	}
	.panel-heading{
		padding:0px !important;
	    background-color: #00234C !important;
	    border-color: #ddd !important;
	    border-radius: 0px !important;
	}
	.fa {
	    float: right !important;
	    width: 23px !important;
	    height: 14px !important;
	    margin-right: 10px !important;
	    margin-top:-18px !important;
		&.arrow-down-header {
		    display: none !important;
		}
		img {
		    margin: 3px 0px !important;
		}
	}
	.collapsed{
		.arrow-down-header {
		    display: block !important;
		}
		.arrow-up-header {
		    display: none !important;
		}
	}
	.collapse.in{
		height:auto !important;
	}
	.heading-text {
	    width: 90% !important;
	}
}
ul.alert-modal-list {
    list-style: none;
}
.modal-link{
	color:#fff;
	text-decoration:underline;
	&:hover{
		color:#DC241F;
	}
}
a.btn.btn-default.btn-publications {
    margin-right:12px;
}

.block-padding {
    &.block-height-small {
        height:35px;
    }
    &.block-height-large {
        height:55px;
    }
}
.careers-wrapper{
	.pager li{
		a{
			border: 0px solid #ddd !important;
			background:none !important;
		}
		&:hover{
			a{
				border: 0px solid #ddd !important;
				background:none !important;
			}
		}
		.fa {
		    padding: 0px 5px !important;
		}
	}
}

.matrix-block:after {
    display: block;
    content: '';
    clear: both;
}

.tv-logo {
    width: 25px;
    position: absolute;
	right: 50px;
	@media screen and (max-width:768px){
		right: 70px;	
	}
	a {
		display: block;
	}
}